<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="模糊搜索名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.code" @change="Search" placeholder="内容页编号"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addcontent">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="code">
        <template v-slot="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="内容表现形式">
        <template v-slot="scope">
          <span>{{ scope.row.content_type==1?'无':scope.row.content_type==2?'单行文本':'多行文本' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图文定义">
        <template v-slot="scope">
          <span>{{ changeImgType(scope.row.image_url_type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="app是否可选">
        <template v-slot="scope">
          <span>{{ scope.row.is_optional==1?'必选':'可选' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="跳转地址定义">
        <template v-slot="scope">
          <span>{{ scope.row.jump_url_type==1?'无':'有' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="页面的页数">
        <template v-slot="scope">
          <span>{{ scope.row.page_num==1?'一页':'多页' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="规则类型">
        <template v-slot="scope">
          <span>{{ changeRule(scope.row.rule_type) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="标题形式">
        <template v-slot="scope">
          <span>{{ scope.row.title_type==1?'无':'有标题' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="scope">
          <el-select v-model="scope.row.status" @change="ChangeStatus(scope.row)">
            <el-option label="下架" :value="1"></el-option>
            <el-option label="测试" :value="2"></el-option>
            <el-option label="上架" :value="3"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editContent(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteContent(scope.row.code)">删除</el-button>
          <router-link style="margin-left:10px" :to="'/iot/contentmanage/rule/'+scope.row.code+'&'+scope.row.rule_type">
            <el-button type="info" size="small">规则设定</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'内容管理'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="名称" prop="name" label-width="110px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="内容表现形式" prop="content_type" label-width="110px">
          <el-select v-model="form.content_type" placeholder="请选择">
            <el-option label="无" :value="1"></el-option>
            <el-option label="单行文本" :value="2"></el-option>
            <el-option label="多行文本" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图文定义" prop="image_url_type" label-width="110px">
          <el-select v-model="form.image_url_type" placeholder="请选择">
            <el-option label="无" :value="1"></el-option>
            <el-option label="图片" :value="2"></el-option>
            <el-option label="视频" :value="3"></el-option>
            <el-option label="富文本" :value="4"></el-option>
            <el-option label="图片或视频" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="app是否可选" prop="is_optional" label-width="110px">
          <el-select v-model="form.is_optional" placeholder="请选择">
            <el-option label="必选" :value="1"></el-option>
            <el-option label="可选" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转地址定义" prop="jump_url_type" label-width="110px">
          <el-select v-model="form.jump_url_type" placeholder="请选择">
            <el-option label="无" :value="1"></el-option>
            <el-option label="有" :value="2"></el-option>
            <el-option label="可选" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="页面的页数" prop="page_num" label-width="110px">
          <el-select v-model="form.page_num" placeholder="请选择">
            <el-option label="一页" :value="1"></el-option>
            <el-option label="多页多标题" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规则类型" prop="rule_type" label-width="110px">
          <el-select v-model="form.rule_type" placeholder="请选择">
            <el-option label="按终端定义" :value="1"></el-option>
            <el-option label="按产品定义" :value="2"></el-option>
            <el-option label="终端+产品" :value="3"></el-option>
            <el-option label="终端+版本" :value="4"></el-option>
            <el-option label="终端+国家" :value="5"></el-option>
            <el-option label="自由组合" :value="100"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题形式" prop="title_type" label-width="110px">
          <el-select v-model="form.title_type" clearable placeholder="请选择">
            <el-option label="无" :value="1"></el-option>
            <el-option label="有标题" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
import {rulType,imgUrlType} from '../../../assets/js/dictionary'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        code: '',
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      edit: false,
      form: {
        code: '',
        name: '',
        content_type: '',
        image_url_type: '',
        is_optional: '',
        jump_url_type: '',
        page_num: '',
        rule_type: '',
        title_type: '',
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        content_type: [{ required: true, message: '请选择内容表现形式', trigger: 'blur' }],
        image_url_type: [{ required: true, message: '请选择图文定义', trigger: 'blur' }],
        is_optional: [{ required: true, message: '请选择app是否可选', trigger: 'blur' }],
        jump_url_type: [{ required: true, message: '请选择跳转地址定义', trigger: 'blur' }],
        page_num: [{ required: true, message: '请选择页面的页数', trigger: 'blur' }],
        rule_type: [{ required: true, message: '请选择规则类型', trigger: 'blur' }],
        title_type: [{ required: true, message: '请选择标题形式', trigger: 'blur' }]
      },

    }
  },
  computed: {},
  watch: {},
  methods: {
    ChangeStatus (item) {
      this.api.ContentStatus({
        code: item.code,
        status: item.status
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('状态修改成功')
          return
        }
        this.$message.error('状态修改失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search(){
      this.api.ContentList({
        page: this.search.page,
        limit: this.search.limit,
        kname: this.search.kname,
        code: this.search.code
      }).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addcontent () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editContent (item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      this.api.ContentDetail({code:item.code}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm(){
      this.update()
    },
    update(){
      this.api.ContentUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    editconfirm(){
      this.update()
    },
    deleteContent(item) {
      this.$confirm('是否删除该内容页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ContentDelete({code: item}).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    changeRule(item){
      return util.handleValueDisplay(rulType,item)
    },
    changeImgType (item){
      return util.handleValueDisplay(imgUrlType,item)
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
