// 内容管理--规则类型
let rulType = [{
		value: '终端',
		key: 1
	},
	{
		value: '产品',
		key: 2
	},
	{
		value: '终端+产品',
		key: 3
	},
	{
		value: '终端+版本',
		key: 4
	},
	{
		value: '终端+国家',
		key: 5
	},
	{
		value: '自由组合',
		key: 100
	},
]
// 内容管理--图文定义
let imgUrlType = [{
		value: '无',
		key: 1
	},
	{
		value: '图片',
		key: 2
	},
	{
		value: '视频',
		key: 3
	},
	{
		value: '富文本',
		key: 4
	},
	{
		value: 'url',
		key: 5
	},
	{
		value: '富文本或url',
		key: 6
	},
	{
		value: '图片或视频',
		key: 7
	},
]
// 套餐状态
let packStatus = [{
		value: '默认',
		key: 0
	},
	{
		value: '上架',
		key: 1
	},
	{
		value: '下架',
		key: 2
	},
	{
		value: '已删除',
		key: 3
	},
]
// 固件版本状态
let firmStatus = [{
		value: '开发版本',
		key: 1
	},
	{
		value: '测试版本',
		key: 2
	},
	{
		value: '上线版本',
		key: 3
	},
	{
		value: 'recall',
		key: 4
	},
]
// 申请出厂状态
let proStatus = [{
		value: '未处理',
		key: 1
	},
	{
		value: '通过',
		key: 2
	},
	{
		value: '拒绝',
		key: 3
	},
	{
		value: '停止配额',
		key: 4
	},
	{
		value: '申请提交异常',
		key: 5
	},
	{
		value: '正在出厂',
		key: 6
	},
	{
		value: '审核出厂异常',
		key: 7
	},
]
// 日志事件类型
let logEventStatus = [{
		value: '上线',
		key: 1
	},
	{
		value: '下线',
		key: 2
	},
	{
		value: '设备激活',
		key: 3
	},
	{
		value: '设备重置',
		key: 4
	},
	{
		value: '指令下发',
		key: 5
	},
	{
		value: '固件升级',
		key: 6
	},
	{
		value: '数据点上报',
		key: 7
	},
	{
		value: '设备信号量',
		key: 8
	},
	{
		value: '设备重启',
		key: 9
	},
	{
		value: '定时信息',
		key: 10
	},
]
// 推送消息审核状态
let msg_status = [{
		value: '待审核',
		key: 0
	},
	{
		value: '审核不通过',
		key: 1
	},
	{
		value: '审核通过，未执行',
		key: 2
	},
	{
		value: '执行中',
		key: 3
	},
	{
		value: '已完成',
		key: 4
	},
	{
		value: '取消执行',
		key: 5
	},
	{
		value: '执行失败',
		key: 6
	},
]
// 订单状态
let order_status = [{
		value: '未支付',
		key: 0
	},
	{
		value: '已支付',
		key: 1
	},
	{
		value: '已取消',
		key: 2
	},
	{
		value: '已过期',
		key: 3
	},
	{
		value: '已删除',
		key: 4
	},
	{
		value: '测试付款',
		key: 5
	},
	{
		value: '待收款',
		key: 6
	},
]

// 付款类型
let pay_type = [{
		value: '微信',
		key: 1
	},
	{
		value: '支付宝',
		key: 2
	},
	{
		value: 'PayPal',
		key: 3
	},
	{
		value: 'PayPal',
		key: 4
	},
	{
		value: '后台开通',
		key: 5
	},
	{
		value: '免费激活',
		key: 6
	},
	{
		value: 'Cb-Paypal',
		key: 20
	},
	{
		value: 'Cb-Paypal',
		key: 21
	},
	{
		value: 'Cb-Crad',
		key: 22
	},
	{
		value: 'Cb-Crad',
		key: 23
	}
]

export {
	rulType,
	imgUrlType,
	packStatus,
	firmStatus,
	proStatus,
	logEventStatus,
	msg_status,
	order_status,
	pay_type
}
